<template>
    <v-container>
        <v-layout row>
            <v-spacer></v-spacer>
            <v-btn icon>
                <v-icon color="grey">fa-times</v-icon>
            </v-btn>
        </v-layout>
        <v-layout row>
            <v-card flat width="1024">
                <v-toolbar color="white" flat>
                    <span class="page-title" text-md-left>Create Invoice</span>
                    <v-spacer></v-spacer>
                    <img :src="getLogoUrl()" />
                </v-toolbar>
            </v-card>
        </v-layout>
        <v-layout row>
            <v-card flat width="1024">
                <v-toolbar flat>
                    <span class="section-title">Account Details</span>
                </v-toolbar>
                <v-card-text>
                    <v-layout row v-if="account != null">
                        <v-flex md4>
                            <v-layout row style="font-weight: bold;">
                                {{ account.Name1 }}
                            </v-layout>
                            <v-layout row style="font-weight: bold;" v-if="!isEmpty(account.Name2)">
                                {{ account.Name2 }}
                            </v-layout>
                            <v-layout row>
                                ATTN: {{ account.billTo_Name1 + ' ' + account.billTo_Name2 }}
                            </v-layout>
                        </v-flex>
                        <v-flex md4>
                            <v-layout row style="font-weight: bold;">
                                Bill To Address:
                            </v-layout>
                            <v-layout row>
                                {{ account.Address1 }}
                            </v-layout>
                            <v-layout row>
                                {{account.cityName}}, {{ account.stateProvCode }}
                            </v-layout>
                            <v-layout row>
                                {{account.mailCode}}
                            </v-layout>
                        </v-flex>
                        <v-flex md4>
                            <v-layout row>
                                <v-flex v-if="showshipping" md8>
                                    <v-layout row style="font-weight: bold;">
                                        Ship To Address:
                                    </v-layout>
                                    <v-layout row>
                                        {{ shippingAddress1 }}
                                    </v-layout>
                                    <v-layout row v-if="!isEmpty(shippingAddress2)">
                                        {{ shippingAddress2 }}
                                    </v-layout>
                                    <v-layout row>
                                        {{ shippingCity }}, {{ shippingProvState }}
                                    </v-layout>
                                    <v-layout row>
                                        {{ shippingPostalZip }}
                                    </v-layout>
                                </v-flex>
                                <v-flex md4 v-if="showshipping">
                                    <v-btn icon>
                                        <v-icon color="grey" @click="shipaddressdialog = true" small>fa-edit</v-icon>
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-layout>
        <v-layout row v-if="showshipping">
            <v-card flat width="1024">
                <v-toolbar flat>
                    <span class="section-title">Shipping and Handling</span>
                </v-toolbar>
                <v-card-text pt-0 mt-0>
                    <v-layout row>
                        <v-radio-group v-model="shippingAmount" row pt-0 mt-0>
                            <v-radio value="30" pa-0 ma-0>
                                <template v-slot:label>
                                    <span style="font-size: 15px;">Standard Shipping ($30)</span>
                                </template>
                            </v-radio>
                            <v-radio value="60">
                                <template v-slot:label>
                                    <span style="font-size: 15px;">Next Day Shipping ($60)</span>
                                </template>
                            </v-radio>
                        </v-radio-group>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-layout>
        <v-layout row>
            <v-card flat width="1024">
                <v-toolbar flat>
                    <span class="section-title">Summary of Charges</span>
                    <v-spacer></v-spacer>
                    <v-btn color="green" dark @click.stop="dialog = true" flat v-if="false">+ ADD</v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-data-table
                        :headers="headers"
                        :items="currentCharges"
                        hide-actions
                    >
                        <template v-slot:items="props">
                            <td class="text-md-left">{{ props.item.name }}</td>
                            <td class="text-md-right">{{ props.item.amount.toLocaleString("en-US",{ style: "currency", currency: "USD" }) }}</td>
                            <td class="text-md-right">{{ props.item.quantity }}</td>
                            <td class="text-md-right">{{ (props.item.amount*props.item.quantity).toLocaleString("en-US",{ style: "currency", currency: "USD" }) }}</td>
                        </template>
                        <template v-slot:no-data>
                            <td colspan="4" class="text-md-center"><span color="success">No charges to display.</span></td>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
            <v-dialog
                v-model="dialog"
                max-width="500px"
                transition="dialog-transition"
                elevation-12
            >
                <v-card>
                    <v-card-title primary-title>
                        <span class="section-title">Add Charge</span>
                    </v-card-title>
                    <v-card-text>
                        <v-layout row>
                            <v-select
                                :items="chargeTypes"
                                v-model="selectedChargeType"
                                label="Charge Description"
                                item-text="name"
                                item-value="id"
                                @change="chargeTypeChanged"
                            ></v-select>
                        </v-layout>
                        <v-layout row>
                            <v-flex md4>
                                <v-text-field
                                    name="quantity"
                                    label="Qty"
                                    maxlength="3"
                                    v-model.number="quantity"
                                    type="number"
                                ></v-text-field>
                            </v-flex>
                            <v-spacer></v-spacer>
                            <v-flex md7 text-md-right>
                                <v-text-field
                                    name="amount"
                                    label="Amount"
                                    type="number"
                                    v-model.number="chargeAmount"
                                >
                                    <template v-slot:prepend>$</template>
                                </v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue" flat @click="dialog = false">CANCEL</v-btn>
                        <v-btn color="green" dark @click="addCharge">ADD</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog
                v-model="editdialog"
                max-width="500px"
                transition="dialog-transition"
                elevation-12
            >
                <v-card>
                    <v-card-title primary-title>
                        <span class="section-title">Edit Charge</span>
                    </v-card-title>
                    <v-card-text>
                        <v-layout row>
                            <v-text-field
                                name="name"
                                label="Charge Description"
                                id="id"
                                v-model="chargeTypeName"
                                disabled
                            ></v-text-field>
                        </v-layout>
                        <v-layout row>
                            <v-flex md4>
                                <v-text-field
                                    name="quantity"
                                    label="Qty"
                                    maxlength="3"
                                    v-model.number="quantity"
                                    type="number"
                                ></v-text-field>
                            </v-flex>
                            <v-spacer></v-spacer>
                            <v-flex md7 text-md-right>
                                <v-text-field
                                    name="amount"
                                    label="Amount"
                                    type="number"
                                    v-model.number="chargeAmount"
                                >
                                    <template v-slot:prepend>$</template>
                                </v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue" flat @click="editdialog = false">CANCEL</v-btn>
                        <v-btn color="green" dark @click="updateCharge">SAVE</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog
                max-width="500px"
                transition="dialog-transition"
                elevation-12
                v-model="shipaddressdialog"
            >
                <v-card>
                    <v-card-title primary-title>
                        <span class="section-title">Edit Shipping Address</span>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="shipaddressdialog = false">
                            <v-icon small color="grey">fa-times</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-layout row>
                            <v-flex md12>
                                <v-text-field
                                    name="address1"
                                    label="Address Line 1"
                                    maxlength="40"
                                    v-model="shippingAddress1"
                                    required
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex md12>
                                <v-text-field
                                    name="address2"
                                    label="Address Line 2"
                                    v-model="shippingAddress2"
                                    maxlength="40"
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex md8>
                                <v-text-field
                                    name="city"
                                    label="City"
                                    v-model="shippingCity"
                                    maxlength="40"
                                    required
                                ></v-text-field>
                            </v-flex>
                            <v-flex md4>
                                <v-text-field
                                    name="stateprov"
                                    label="State/Province"
                                    v-model="shippingProvState"
                                    maxlength="2"
                                    required
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-text-field
                                name="postalzip"
                                label="Postal/Zip Code"
                                required
                                v-model="shippingPostalZip"
                            ></v-text-field>
                        </v-layout>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-layout>
        <v-layout row>
            <v-card flat width="1024">
                <v-card-text>
                    <v-layout row>
                        <v-flex offset-md4 md4 text-md-right>
                            <strong>Subtotal</strong>
                        </v-flex>
                        <v-flex md4 text-md-right>
                            {{
                                chargesSubtotal.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                    currencyDisplay: 'symbol'
                                })
                            }}
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex offset-md4 md4 text-md-right>
                            <strong>Shipping & Handling</strong>
                        </v-flex>
                        <v-flex md4 text-md-right>
                            {{
                                parseFloat(shippingAmount).toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                    currencyDisplay: 'symbol'
                                })
                            }}
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex offset-md4 md4 text-md-right>
                            <strong>Taxes (HST)</strong>
                        </v-flex>
                        <v-flex md4 text-md-right>
                            {{
                                calcTaxes.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                    currencyDisplay: 'symbol'
                                })
                            }}
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex offset-md4 md4 text-md-right>
                            <strong>Total Amount</strong>
                        </v-flex>
                        <v-flex md4 text-md-right>
                            {{
                                totalAmount.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                    currencyDisplay: 'symbol'
                                })
                            }}
                        </v-flex>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-layout>
        <v-layout row>
            <v-card flat width="1024">
                <v-toolbar flat>
                    <span class="section-title">Payment Details</span>
                </v-toolbar>
                <v-card-text>
                    <v-layout row v-if="hasCardOnFile">
                        Use card on file ending in ****{{last4CC}}
                    </v-layout>
                    <v-layout row v-if="!hasCardOnFile">
                        <v-flex xs1>
                            
                        </v-flex>
                    </v-layout>
                    <v-layout row pt-2>
                        <v-btn flat style="color: #7777CC;" @click="clearCard">Use Another Card</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="green" dark>Pay Now</v-btn>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-layout>
    </v-container>
</template>

<script>
import axios from "axios";
import {mapActions,mapState} from "vuex";

export default {
    computed: {
        chargesSubtotal: function(){
            var subtotal = 0;
            this.currentCharges.forEach(e => {
                subtotal += e.amount;
            });

            return subtotal;
        },
        calcTaxes: function(){
            var subtotal = this.chargesSubtotal + parseFloat(this.shippingAmount);
            var taxes = subtotal * 0.13;

            return Math.round(taxes*100)/100;
        },
        totalAmount: function(){
            return this.chargesSubtotal + this.calcTaxes + parseFloat(this.shippingAmount);
        },
        ...mapState(['clientToken'])
    },
    data: () => ({
        clientLogo: "MiCONNECT_CMYK.svg",
        headers: [
            {
                text: "Charge Description",
                value: "name",
                align: "left"
            },
            {
                text: "Price",
                value: "amount",
                align: "right"
            },
            {
                text: "Qty",
                value: "quantity",
                align: "right"
            },
            {
                text: "Total",
                value: "totalamount",
                align: "right"
            }
        ],
        currentCharges: [
        ],
        chargeTypes: [
            
        ],
        dialog: false,
        selectedChargeType: null,
        quantity: 1,
        chargeAmount: 0,
        showshipping: false,
        editdialog: false,
        chargeTypeName: "",
        editChargeId: null,
        shippingAmount: "0",
        shippingAddress1: "",
        shippingAddress2: "",
        shippingCity: "",
        shippingPostalZip: "",
        shippingProvState: "",
        shipaddressdialog: false,
        account: null,
        accountNumber: "",
        stateProvs: null,
        stateProv: null,
        hasCardOnFile: false,
        last4CC: "",
        ccPerson: {
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: ""
        },
        ccAddress: null,
        ccDetails: null,
    }),
    methods: {
        ...mapActions(['updateToken']),
        getLogoUrl(){
            return require('@/assets/img/' + this.clientLogo);
        },
        addCharge(){
            var chargeType = this.chargeTypes.find(i=> i.Id == this.selectedChargeType);

            var maxid = 0;
            this.currentCharges.forEach(e => {
                if(e.id > maxid)
                    maxid = e.id;
            });
            
            var chargeToAdd = {
                id: maxid + 1,
                name: chargeType.Name,
                chargeTypeId: chargeType.id,
                quantity: 1,
                amount: chargeType.Amount
            }

            this.currentCharges.push(chargeToAdd);

            if(chargeType.needsshipping){
                this.showshipping = true;
                this.shippingAmount = "30";
            }
        },
        chargeTypeChanged(){
            var selectedChargeType = this.chargeTypes.find(i => i.id == this.selectedChargeType);
            this.quantity = selectedChargeType.quantity;
            this.chargeAmount = selectedChargeType.amount;
        },
        removeCharge(id){
            var index = this.currentCharges.indexOf(this.currentCharges.find(i=>i.id == id));
            this.currentCharges.splice(index,1);

            // check if we still need shipping for remaining charges
            var ship = false;
            this.currentCharges.forEach(e => {
                var type = this.chargeTypes.find(c => c.id == e.chargeTypeId);
                if(type.needsshipping){
                    ship = true;
                }
            });

            this.showshipping = ship;

            if(!ship){
                this.shippingAmount = "0";
            }
        },
        editCharge(id){
            var index = this.currentCharges.indexOf(this.currentCharges.find(i=>i.id == id));
            // Now we need the charge type of the selected charge
            var chargeTypeId = this.currentCharges[index].chargeTypeId;
            this.chargeTypeName = this.chargeTypes.find(i=>i.id == chargeTypeId).name;
            this.chargeAmount = this.currentCharges[index].amount;
            this.quantity = this.currentCharges[index].quantity;
            this.editChargeId = id;

            // show the edit dialog
            this.editdialog = true;
        },
        updateCharge(){
            var index = this.currentCharges.indexOf(this.currentCharges.find(i=>i.id == this.editChargeId));
            this.currentCharges[index].quantity = this.quantity;
            this.currentCharges[index].amount = this.chargeAmount;
            this.editdialog = false;
        },
        isEmpty(str){
            return (!str || 0 === str.length);
        },
        updateShipAddr(){
            this.shipaddressdialog = false;
        },
        deleteQueryString(queryString){
          let query = Object.assign({}, this.$route.query);
          delete query[`${queryString}`];
          this.$router.replace({ query });
        },
        async getAccountDetails(){
            await axios.post(process.env.VUE_APP_PGAPI + "api/boss40/getaccount",{"accountNumber" : this.accountNumber })
                .then((resp) => {
                    this.account = resp.data;
                });
        },
        async getChargeTypes(){
            await axios.get(process.env.VUE_APP_PGAPI + "api/boss40/getchargetypes")
                .then((resp) => {
                    this.chargeTypes = resp.data;
                });
        },
        async hasSavedCC() {
          var BAN = this.accountNumber;
          var PayGateToken = this.clientToken;
          var postData = { BAN, PayGateToken };

          var resp = await axios.post(process.env.VUE_APP_PGAPI + 'api/values/getkey' , postData);
          
          this.last4CC = resp.data;

          if(this.last4CC != undefined && this.last4CC.length == 4)
            this.hasCardOnFile = true;
        },
        clearCard(){
            this.hasCardOnFile = false;
            this.last4CC = "";
        }
    },
    async created() {
        // Check URL for client token and replace in store if it is there
        var urltoken = this.$route.query.paygatetoken;

        if(urltoken != undefined)
        {
            this.updateToken(urltoken);
            this.deleteQueryString("paygatetoken");
        }

        this.accountNumber = this.$route.query.accountNumber;
        if(this.accountNumber != undefined && this.accountNumber != null)
            await this.getAccountDetails();

        await this.getChargeTypes();

        this.selectedChargeType = parseInt(this.$route.query.chargeType);

        this.addCharge();

        await this.hasSavedCC();

        //var tx3Serial = this.$route.query.tx3Serial;

        //console.log(tx3Serial);
    },
    mounted(){
        this.clientLogo = "MiCONNECT_CMYK.svg";
    },
    updated(){
        if(this.account!=null){
            this.shippingAddress1 = this.account.Address1;
            this.shippingAddress2 = this.account.Address2;
            this.shippingCity = this.account.cityName;
            this.shippingProvState = this.account.stateProvCode;
            this.shippingPostalZip = this.account.mailCode;
        }
    }
}
</script>

<style scoped>
.page-title {
    font-size: x-large;
    font-weight: bold;
}

.section-title {
    font-weight: bold;
}
</style>