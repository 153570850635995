<template>
    <v-container>
        <v-layout row>
            <v-spacer></v-spacer>
            <v-btn icon>
                <v-icon color="grey">fa-times</v-icon>
            </v-btn>
        </v-layout>
        <v-layout row>
            <v-card flat width="1024">
                <v-toolbar color="white" flat>
                    <span class="page-title" text-md-left>Password Recovery Authorization Form</span>
                    <v-spacer></v-spacer>
                    <img :src="getLogoUrl()" />
                </v-toolbar>
            </v-card>
        </v-layout>
        <v-layout row>
            <v-card flat width="1024">
                <v-toolbar flat>
                    <span class="section-title">Property Management's Contact Information</span>
                </v-toolbar>
            </v-card>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    data: () => ({
        clientLogo: "MiCONNECT_CMYK.svg",
        pmName: "",
        pmAddress1: "",
        pmAddress2: "",
        pmCity: "",
        pmStateProv: "",
        pmCountry: "",
        pmPostalZip: "",
        pmTelephone: "",
        inName: "",
        inAddress1: "",
        inAddress2: "",
        inCity: "",
        inCountry: "",
        inStateProv: "",
        inPostalZip: "",
        inContactName: "",
        inContactPhone: "",
        siteAddress1: "",
        siteAddress2: "",
        siteCity: "",
        siteCountry: "",
        siteStateProv: "",
        sitePostalZip: "",
        siteContactName: "",
        siteContactPhone: "",
        panelId: "",
        passwordLostReason: "",
        ccNumber: "",
        ccHolderName: "",
        ccExpiry: "",
        ccSecurityCode: ""
    }),
    methods: {
        getLogoUrl(){
            return require('@/assets/img/' + this.clientLogo);
        },
    }
}
</script>

<style scoped>
.page-title {
    font-size: x-large;
    font-weight: bold;
}

.section-title {
    font-weight: bold;
}
</style>