import { render, staticRenderFns } from "./Tx3PasswordReset.vue?vue&type=template&id=501058d5&scoped=true&"
import script from "./Tx3PasswordReset.vue?vue&type=script&lang=js&"
export * from "./Tx3PasswordReset.vue?vue&type=script&lang=js&"
import style0 from "./Tx3PasswordReset.vue?vue&type=style&index=0&id=501058d5&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "501058d5",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VCard,VContainer,VIcon,VLayout,VSpacer,VToolbar})
